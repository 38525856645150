import $ from 'jquery';
import 'bootstrap';
import configureDialogs  from './searchDialogs';

declare global {
  interface Window {
    $: typeof $;
    jQuery: typeof $;
  }
}

window.$ = $;
window.jQuery = $;

$(document).ready(function () {
  "use strict";

  $('[data-toggle="tooltip"]').tooltip();
  $('.dropdown-toggle').dropdown();
  configureDialogs();
});
