import $ from 'jquery';
import 'bootstrap';

declare global {
  interface Window {
    $: typeof $;
    jQuery: typeof $;
  }
}

window.$ = $;
window.jQuery = $;

export default function configureDialogs() {

  interface DialogElement extends HTMLElement {
    showModal: () => void;
    close: () => void;
    returnValue?: string;
  }

  function handleSearchInput(dialog: DialogElement, name: string, path: string): void {
    if (name === "") {
      console.log("no name, closing dialog");
      dialog.close();
    } else {
      console.log(`about to navigate to ${path}${name}`);
      window.open(path + name, "_self");
    }
  }

  function openSearchDialog(path: string, title: string, label: string): void {
    console.log("Loading menu...");
    const dialog: DialogElement | null = document.getElementById('dialog-form') as DialogElement | null;
    if (!dialog) return;

    dialog.showModal();

    const labelEl: HTMLLabelElement | null = dialog.querySelector("label");
    if (labelEl) {
      labelEl.textContent = label;
    }

    const titleEl: HTMLSpanElement | null = dialog.querySelector("h4 span");
    if (titleEl) {
      titleEl.textContent = title;
    }

    const inputEl: HTMLInputElement | null = dialog.querySelector("#strpart");
    if (inputEl === null) {
      return;
    }

    const searchBtn: HTMLButtonElement | null = dialog.querySelector("button#search");
    if (searchBtn === null) {
      return;
    }

    const cancelBtn: HTMLButtonElement | null = dialog.querySelector("button#cancel");
    cancelBtn?.addEventListener("click", () => {
      console.log("cancel clicked");
      dialog.close();
    });

    inputEl.addEventListener('change', (e: Event) => {
      const target = e.target as HTMLInputElement;
      console.log("input changed: " + target.value);
      searchBtn.value = target.value;
    });

    searchBtn.addEventListener("click", () => {
      console.log("search clicked");
      const name = inputEl.value;
      console.log("search clicked", name);
      handleSearchInput(dialog, name, path);
    });

    dialog.addEventListener('close', () => {
      const value = dialog.returnValue ? `ReturnValue: ${dialog.returnValue}.` : "No return value";
      const name = inputEl.value;
      console.log(name);
      handleSearchInput(dialog, name, path);
      console.log(value);
      console.log("Dialog closed");
    });
  }

  // Bind events using jQuery
  $("#ordersearchref").on("click", function () {
    openSearchDialog("/orders/listref/", "applicatie 1", "Referentie");
  });

  $("#ordersearchcust").on("click", function () {
    openSearchDialog("/orders/listcust/", "applicatie 1", "Klantnaam");
  });

  $("#ordersearchnumber").on("click", function () {
    openSearchDialog("/orders/listnumber/", "applicatie 1", "Productnummer");
  });

  $("#ordersearchcolor").on("click", function () {
    openSearchDialog("/orders/listcolor/", "applicatie 1", "Kleur");
  });

  $("#ordersearchdeliverydate").on("click", function () {
    openSearchDialog("/orders/listdeliverydate/", "applicatie 1", "Leverdatum");
  });

  // Service orders search
  $("#serviceappsearchref").on("click", function () {
    openSearchDialog("/serviceapp/listref/", "applicatie 6", "Referentie");
  });

  $("#serviceappsearchcust").on("click", function () {
    openSearchDialog("/serviceapp/listcust/", "applicatie 6", "Klantnaam");
  });

  $("#serviceappsearchnumber").on("click", function () {
    openSearchDialog("/serviceapp/listnumber/", "applicatie 6", "Productnummer");
  });
}
